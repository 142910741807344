import React from 'react'
import { Link as GatsbyLink } from 'gatsby'
import { StaticImage } from 'gatsby-plugin-image'

import SEO from '../../components/seo'
import NewsSectionBlock from '../../components/NewsSectionBlock'
import Typography from '../../components/Common/Typography'
import List from '../../components/Common/List'
import Link from '../../components/Common/Link'

import { useTranslations } from '../../hooks/use-translations'
import { PathEnum } from '../../types/pages'

import * as s from '../../pages/news.module.scss'

const DocsAndTanglIntegration = () => {
  const { getPath } = useTranslations()
  return (
    <>
      <SEO
        title="Tangl интегрировала свою BIM-платформу в среду общих данных SIGNAL DOCS"
        description="IT-компании Тангл и SIGNAL успешно интегрировали свои решения, что позволило пользователям использовать отечественные продкты независимо от иностранных компания, позволяя работать с BIM-моделями в браузере и со смартфонов."
      />
      <NewsSectionBlock date="22.05.2024">
        <Typography variant="h1" color="blue">
          BIM-платформа Tangl интегрирована с SIGNAL DOCS
        </Typography>
        <Typography variant="body1">
          Российские разработчики BIM-решений Tangl и SIGNAL объявили об
          успешной интеграции своих продуктов: платформы Tangl и среды общих
          данных SIGNAL DOCS. В результате сотрудничества бизнес-потребители
          получили возможность использовать отечественную, независимую от
          иностранных компаний среду общих данных для работы с BIM-моделями
          через браузер и со смартфонов.
        </Typography>
        <List>
          <li>
            <strong>Tangl</strong> – это платформа для автоматизированной работы
            с данными BIM-модели в строительстве. Она объединяет в экосистему
            несколько IT-решений, которые позволяют проводить проверку моделей
            на любом этапе строительства, автоматизировать сметную документацию
            и работать с физобъемами, проверять модель на коллизиии и т.д.
          </li>
          <li>
            <strong>SIGNAL DOCS</strong> – это среда общих данных, облачное
            хранилище для обмена файлами и хранения данных на проекте, с
            поддержкой версионности и ролевой системы доступа. В систему можно
            загружать любые форматы файлов, любого размера, просматривать
            PDF-документы и редактировать Google-документы.
          </li>
        </List>
        <Typography variant="body1">
          Интеграция решений была выполнена на основе опубликованного API
          help.tangl.cloud. Пользователь может выбрать любые IFC файлы в SIGNAL
          DOCS и в 2 клика отправить на обработку в Tangl. Данная обработка
          выполняется для каждого файла единожды и затем доступна для просмотра
          всеми пользователями, у кого есть доступ.
        </Typography>
        <div className={s.imageWrap}>
          <StaticImage
            width={780}
            src="../../assets/images/news/docs-and-tangl-integration/image-1.jpg"
            alt="BIM-платформа Tangl интегрирована с SIGNAL DOCS"
            title="BIM-платформа Tangl интегрирована с SIGNAL DOCS"
            placeholder="blurred"
          />
        </div>
        <Typography variant="body1">
          Ранее в SIGNAL DOCS для просмотра CAD-форматов использовался Autodesk
          Forge Viewer, а теперь дополнительно добавилась возможность просмотра
          BIM-моделей в IFC-формате с помощью Tangl-вьювера. При загрузке файла
          формата IFC пользователь может отправить его на конвертацию помимо
          Forge еще и в Tangl, а затем, после ее выполнения, просматривать
          модель во вьювере Tangl.
        </Typography>
        <div className={s.imageWrap}>
          <StaticImage
            width={780}
            src="../../assets/images/news/docs-and-tangl-integration/image-2.jpg"
            alt="BIM-платформа Tangl интегрирована с SIGNAL DOCS 2"
            title="BIM-платформа Tangl интегрирована с SIGNAL DOCS 2"
            placeholder="blurred"
          />
        </div>
        <Typography variant="body1">
          «Благодаря интеграции SIGNAL и Tangl бизнес получил отечественную,
          независимую от иностранных компаний Среду Общих Данных, которая
          позволяет работать с BIM-моделями через браузер и со смартфонов. Для
          пользователей SIGNAL, на данный момент использующих Autodesk Forge
          Viewer, альтернативный просмотрщик моделей – это дополнительный «план
          В» на случай, если вдруг начнутся какие-нибудь блокировки с той или
          иной стороны. Таким образом мы снижаем риски для компаний остаться
          вообще без инструментов для работы с моделями, что клиенты уже очень
          оценили. Мы видим это по увеличившейся активности в запросах на
          тестирование от окологосударственных и региональных компаний», –
          прокомментировал Александр Попов, Технический директор компании
          SIGNAL.
        </Typography>
        <div className={s.imageWrap}>
          <StaticImage
            width={780}
            src="../../assets/images/news/docs-and-tangl-integration/image-3.jpg"
            alt="BIM-платформа Tangl интегрирована с SIGNAL DOCS 3"
            title="BIM-платформа Tangl интегрирована с SIGNAL DOCS 3"
            placeholder="blurred"
          />
        </div>
        <Typography variant="body1">
          Это не первый опыт сотрудничества Tangl и SIGNAL. Ведущие специалисты
          компаний состоят в клубе BIM-лидеров, а также совместно перевели
          классификатор Uniformat на русский язык. В планах – продолжение
          сотрудничества. В частности, партнеры намерены расширить интеграцию
          решений в части добавления функционала по проверке моделей и подсчету
          объемов от Tangl в SIGNAL DOCS. Также планируется улучшать
          стабильность конвертации и просмотра файлов и реализовать привязку
          замечаний к элементам в Tangl.
        </Typography>
        <Typography variant="body1">
          Кроме того, SIGNAL ведет разработку плагина, который обеспечит
          публикацию моделей из Revit, сразу конвертируя их в нужный для Tangl
          формат. Это позволит пользователям просматривать помимо IFC еще и
          RVT-формат в системе SIGNAL DOCS через Tangl-вьювер.
        </Typography>
        <Typography variant="body1">
          «В последние годы одной из заметных проблем для тех компаний, которые
          перешли на BIM, стала интеграция отечественных решений друг с другом.
          Ведь раньше многие разработчики делали ставку на интеграцию с
          глобальными платформами, которые сейчас стали недоступны. Тангл
          уделяет решению этой проблемы много внимания: мы планомерно наращиваем
          число партнеров, обеспечивая интеграцию нашей платформы с решениями
          ведущих российских вендоров. И мы рады, что смогли сделать еще один
          шаг в этом направлении», – добавил Андрей Белькевич, генеральный
          директор компании Tangl.
        </Typography>
        <Typography variant="body1">
          <strong>Компания Tangl</strong> – российский разработчик программного
          обеспечения. Штаб-квартира организации находится в Екатеринбурге.
          Компания является участником Ассоциации разработчиков программных
          продуктов «Отечественный софт». Компания с 2020 года развивает
          собственную облачную систему для работы с BIM-данными. Платформа Tangl
          входит в реестр отечественного ПО. Подробнее:{' '}
          <Link
            href="https://tangl.cloud/"
            rel="nofollow noreferrer"
            target="_blank"
          >
            www.tangl.cloud
          </Link>
        </Typography>
        <Typography variant="body1">
          <strong>SIGNAL</strong> — российская ИТ‑компания, специализирующаяся
          на разработке решений для цифровой трансформации стоительной отрасли.
          Подробнее:{' '}
          <GatsbyLink to={getPath(PathEnum.Home)} className="has-text-primary">
            https://sgnl.pro
          </GatsbyLink>
        </Typography>
      </NewsSectionBlock>
    </>
  )
}

export default DocsAndTanglIntegration
